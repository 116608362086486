import { config } from "trix"

function fa_icon(name) { return `<i class='fa fa-${name}'></i>` }

function heading_icon(level) {
  return `<span class="fa-layers fa-fw">
    <i class="fa fa-heading" data-fa-transform='left-5' style='vertical-align: middle;'></i>
    <span class="fa-layers-text" data-fa-transform='right-6 down-3 shrink-2'>${level}</span>
  </span>`
}


delete config.blockAttributes.heading1

// config.textAttributes.mark = {
//   tagName: "mark",
//   inheritable: true,
//   parser: (element) => {
//     const style = window.getComputedStyle(element)
//     return style["backgroundColor"] == "yellow"
//   }
// }

config.textAttributes.underline = {
  tagName: "u",
  inheritable: true,
  parser: (element) => {
    const style = window.getComputedStyle(element)
    return style["textDecoration"] == "underline"
  }
}

config.textAttributes.smallCaps = {
  tagName: "sc",
  inheritable: true,
  parser: (element) => {
    const style = window.getComputedStyle(element)
    return style["fontVariant"] == "small-caps"
  }
}

const caps_icon = `
<span class="fa-layers">
  <span class="fa-layers-text" data-fa-transform="" style="font-variant: small-caps;">Abc</span>
</span>
`

const { lang } = config

config.toolbar = {
  getDefaultHTML: () => {

    const template = `
      <div class='bg-white py-2'>
      <div class="flex flex-wrap w-100">
        <div class="btn-group my-1 mr-3" data-trix-button-group="text-tools">
          <button type="button" class="btn btn-secondary" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${fa_icon("bold")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${fa_icon("italic")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="underline" data-trix-key="u" title="${lang.underline}" tabindex="-1">${fa_icon("underline")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${fa_icon("strikethrough")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="smallCaps" title="${lang.small_caps}" tabindex="-1">${caps_icon}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${fa_icon("link")}</button>
        </div>
        <div class="btn-group my-1 mr-3" data-trix-button-group="block-tools">
          <button type="button" class="btn btn-secondary" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${fa_icon("quote-right")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${fa_icon("code")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${fa_icon("list-ul")}</button>
          <button type="button" class="btn btn-secondary" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${fa_icon("list-ol")}</button>
          <button type="button" class="btn btn-secondary" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${fa_icon("outdent")}</button>
          <button type="button" class="btn btn-secondary" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${fa_icon("indent")}</button>
        </div>
        <button type="button" class="btn btn-secondary mr-3 my-1" data-trix-action="attachFiles" title="Attach Files" tabindex="-1">${fa_icon("paperclip")}</button>
        <div class="btn-group ml-auto my-1" data-trix-button-group="history-tools">
          <button type="button" class="btn btn-secondary" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${fa_icon("undo")}</button>
          <button type="button" class="btn btn-secondary" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${fa_icon("redo")}</button>
        </div>
      </div>
      <div class="absolute bg-white shadow" data-trix-dialogs>
        <div class="p-3 bg-secondary shadow border rounded" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <input type="url" name="href" class="form-control" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>

          <div class='mt-2'>
            <input type="button" class="btn btn-secondary" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="btn btn-secondary" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>`

    return template
  }
}